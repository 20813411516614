/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "as-seen-on",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#as-seen-on",
    "aria-label": "as seen on permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "As Seen On:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Business Insider:"), " ", React.createElement(_components.a, {
    href: "https://www.businessinsider.com/sc/how-smart-technology-in-hearing-aids-can-treat-hearing-loss-2020-9",
    className: "c-md-a"
  }, "“How two entrepreneurs are revolutionizing hearing care with smart technology”")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Forbes:"), " ", React.createElement(_components.a, {
    href: "https://www.forbes.com/sites/forbesbusinesscouncil/2020/10/29/how-masks-are-unmasking-hearing-loss-around-the-globe/?sh=4e2cfd0d59b4",
    className: "c-md-a"
  }, "“How Masks Are Unmasking Hearing Loss Around The Globe”")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Medium.com:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://medium.com/actoncapital/listen-up-how-a-startup-from-berlin-revolutionized-hearing-worldwide-7cdfb6041fe",
    className: "c-md-a"
  }, "“How a Startup from Berlin Revolutionized Hearing Worldwide“")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://medium.com/authority-magazine/paul-crusius-of-hear-com-5-things-you-should-do-to-optimize-your-wellness-after-retirement-d5908b81bff0",
    className: "c-md-a"
  }, "“Paul Crusius of Hear.com: 5 Things You Should Do to Optimize Your Wellness After Retirement“")), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Newsweek:"), " ", React.createElement(_components.a, {
    href: "https://www.newsweek.com/americas-best-customer-service-2021",
    className: "c-md-a"
  }, "“America’s Best Hearing Companies”")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "ThriveGlobal.com:"), " ", React.createElement(_components.a, {
    href: "https://thriveglobal.com/stories/paul-crusius-build-a-culture-that-you-yourself-like/",
    className: "c-md-a"
  }, "“Paul Crusius: Build a Culture You Yourself Like”")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "WebMD:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.webmd.com/connect-to-care/hearing-loss/causes-and-symptoms-of-age-related-hearing-loss",
    className: "c-md-a"
  }, "“Age-Related Hearing Loss: Causes, Symptoms, and What You Can Do”")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.webmd.com/connect-to-care/hearing-loss/how-is-hearing-loss-tested",
    className: "c-md-a"
  }, "“How Is Hearing Loss Tested“")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.webmd.com/connect-to-care/hearing-loss/treatment_options_for_sudden_hearing_loss",
    className: "c-md-a"
  }, "“Sudden Hearing Loss Treatment Options“")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "https://www.webmd.com/connect-to-care/hearing-loss/causes-and-symptoms-of-conductive-hearing-loss",
    className: "c-md-a"
  }, "“Conductive Hearing Loss: Causes and Symptoms“")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
